<template>
  <highcharts :options="consumoCCEEChart" />
</template>

<script>
import dayjs from 'dayjs';
import { Chart } from 'highcharts-vue';
import { i18n } from '../main';

export default {
  components: {
    highcharts: Chart
  },
  props: {
    options: Array,
    mesReferencia: null,
    datas: Array,
    nomePontoMedicao: String,
    tipoGrafico: String,
    valoresRealizados: Array,
    valoresSimulados: Array
  },
  created() {
    const date = dayjs(this.mesReferencia).format('MMMM/YYYY');
    this.consumoCCEEChart.title.text = `${this.nomePontoMedicao}  - Medição CCEE: ${date}`;
    this.consumoCCEEChart.subtitle.text = `${this.tipoGrafico}`;
    this.consumoCCEEChart.xAxis.categories = this.datas;
    this.consumoCCEEChart.series[0].data = this.valoresRealizados;
    this.consumoCCEEChart.series[1].data = this.valoresSimulados;
  },
  data() {
    return {
      consumoCCEEChart: {
        chart: {
          type: 'column'
        },
        title: {
          text: ''
        },
        subtitle: {
          text: ''
        },
        xAxis: {
          categories: []
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Valores em MWh'
          },
          labels: {
            formatter: function () {
              return this.value;
            }
          },
          stackLabels: {
            enabled: true,
            style: {
              fontWeight: 'bold',
              color: 'gray'
            },
            format: '{total:.3f}'
          }
        },

        tooltip: {
          headerFormat: '<b>{point.x}</b><br/>',
          pointFormat:
            '{series.name}: {point.y:.3f}<br/>Total: {point.stackTotal:.3f}'
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            dataLabels: {
              format: '{point.y:.3f}'
              // enabled: true
            }
          }
        },
        series: [
          {
            name: i18n.tc('consumptionCCEE.accomplished'),
            color: '#009B3A',
            data: [],
            tooltip: {
              valueDecimals: 1,
              valueSuffix: ' MWh',
              valuePrefix: '',
              split: true
            },
            index: 2,
            legendIndex: 1
          },
          {
            name: i18n.tc('consumptionCCEE.simulated'),
            color: '#E57200',
            data: [],
            tooltip: {
              valueDecimals: 1,
              valueSuffix: ' MWh',
              valuePrefix: ''
            },
            index: 1,
            legendIndex: 2
          }
        ],
        credits: {
          enabled: false
        }
      }
    };
  }
};
</script>
